import Vue from 'vue';
import { ZicketContent } from 'zicket-vue-components';
import { axiosWithoutLoading } from './axios-instance';
// Criar uma nova instância do componente ZicketContent com a prop modificada
const CustomZicketContent = Vue.extend(ZicketContent);

CustomZicketContent.options.props.axios = {
    type: Function,
    default: axiosWithoutLoading
};

// Registrar o componente personalizado
Vue.component('ZicketContent', CustomZicketContent);
