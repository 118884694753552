<template>
  <b-modal id="modal-new-events" hide-header hide-footer centered class="modal-new-events">
    <div class="modal-new-events-content">
      <img src="@/assets/img/footer-logo.png" />
      <h2>{{ $t("modal.createEvent.title") }}</h2>
    </div>
    <form @submit.prevent="handleSubmit" class="modal-new-events-form">
      <input type="hidden" name="lang" :value="$store.getters['Site/locale']">
      <div class="row">
        <div class="col-sm-6">
          <input class="input" name="first_name" type="text" :placeholder="$t('modal.createEvent.fields.firstName')"
            required />
        </div>
        <div class="col-sm-6">
          <input class="input" name="last_name" type="text" :placeholder="$t('modal.createEvent.fields.lastName')"
            required />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <input class="input" name="name_company" type="text" :placeholder="$t('modal.createEvent.fields.nameCompany')"
            required />
        </div>
        <div class="col-sm-6">
          <input class="input" name="email" type="email" :placeholder="$t('modal.createEvent.fields.email')" required />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <input class="input" name="mobile" type="text" :placeholder="$t('modal.createEvent.fields.mobile')" required />
        </div>
        <div class="col-sm-6 d-flex flex-column">
          <input class="input" name="password_plan" type="password" :placeholder="$t('modal.createEvent.fields.password')"
            required />
          <span class="help-block">{{
            $t("modal.createEvent.fields.passwordHelp")
          }}</span>
        </div>
      </div>

      <div class="input-textarea">
        <textarea class="textarea" name="description" :placeholder="$t('modal.createEvent.fields.description')"
          id="description" cols="30" rows="5" required></textarea>
      </div>

      <div class="form-group form-checkbox">
        <input type="checkbox" class="form-check-input-new custom-checkbox" id="terms" name="accepted_terms" required />
        <a href="/terms" target="_blank" class="form-check-label" for="terms">
          {{ $t("needHelp.acceptTerms") }}
        </a>
      </div>
      <!--  -->
      <div class="recaptcha">
        <vue-recaptcha ref="recaptcha" :sitekey="googleRecaptchaSite" />
      </div>
      <!--  -->
      <div class="modal-new-events-btn">
        <button class="btn-send" type="submit">
          {{ $t("modal.createEvent.button") }}
        </button>
      </div>
    </form>
    <div @click="$bvModal.hide('modal-new-events')" class="btn-closed">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="36" height="36"
        viewBox="0 0 36 36">
        <defs>
          <filter id="Caminho_5170" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
            <feOffset dx="1" dy="1" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="1" result="blur" />
            <feFlood flood-opacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g id="Grupo_1971" data-name="Grupo 1971" transform="translate(2 2)">
          <g transform="matrix(1, 0, 0, 1, -2, -2)" filter="url(#Caminho_5170)">
            <path id="Caminho_5170-2" data-name="Caminho 5170" d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z"
              transform="translate(2 2)" />
          </g>
          <path id="União_1" data-name="União 1"
            d="M20233.115-756.151l-6.717-6.309-6.77,6.355a.346.346,0,0,1-.537-.061.473.473,0,0,1,.049-.615l6.58-6.221-6.584-6.226a.477.477,0,0,1-.045-.615.354.354,0,0,1,.295-.157.274.274,0,0,1,.246.152l6.713,6.307,6.77-6.358a.35.35,0,0,1,.541.061.48.48,0,0,1-.049.615l-6.576,6.221,6.576,6.226a.469.469,0,0,1,.049.615.36.36,0,0,1-.3.157A.273.273,0,0,1,20233.115-756.151Z"
            transform="translate(-20211.352 778.001)" fill="#fff" />
        </g>
      </svg>
    </div>
  </b-modal>
</template>

<script>
import { axiosInstance } from "@/plugins/axios-instance";
import { VueRecaptcha } from "vue-recaptcha";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      googleRecaptchaSite: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE,
      lang: "",
    };
  },

  components: {
    VueRecaptcha,
  },
  computed: {
    ...mapGetters("Site", ["locale"]),
  },
  methods: {
    handleSubmit($event) {
      if ($event.target.checkValidity()) {
        let lang = this.locale;
        let target = $event.target;
        let formData = new FormData(target);
        formData.append("lang", lang);

        axiosInstance({
          method: "POST",
          url: "organizer",
          data: formData,
        })
          .then((response) => response.data.data)
          .then((data) => {
            this.$swal.fire({
              html: data.message,
            });
            this.$bvModal.hide("modal-new-events");
          });
      } else {
        $event.target.reportValidity();
      }
    },
  },
};
</script>

<style lang="scss">
.swal2-html-container {
  font-size: 2em !important;
}

#modal-new-events {
  padding: 0 !important;

  .modal-header,
  .modal-footer {
    display: none;
  }

  .modal-dialog {
    max-width: 81rem;
    margin: auto !important;
  }

  .modal-content {
    border-radius: 0;
    border: 0;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
  }
}

.help-block {
  font-size: 1rem;
}

.modal-new-events {
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;

    @media (max-width: 575px) {
      align-items: flex-start;
    }

    img {
      width: 11rem;
    }

    .logo {
      margin-top: 4rem;
    }

    h2 {
      font-size: var(--font-sz-d-title);
      font-family: "Font Bold";
      color: #000;
      margin-top: 2rem;

      @media (max-width: 991px) {
        font-size: 2rem;
      }
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 5rem;

    @media (max-width: 575px) {
      padding-inline: 0;
    }

    .row {
      width: 100%;

      @media (max-width: 575px) {
        flex-direction: column;
      }
    }

    .input {
      // width: 44rem;
      height: 5rem;
      width: 100%;
      border-radius: 1rem;
      border: 1px solid #bfbfbf;
      font-size: var(--font-sz-d-text);
      font-family: "Font Regular";
      padding: 0 2rem;
      margin-top: 2rem;
      outline: 0;

      &[required] {
        background-image: radial-gradient(var(--primary-color) 10%, transparent 10%),
          radial-gradient(var(--primary-color) 15%, transparent 15%);
        background-size: 3rem 3rem;
        background-position: top right;
        background-repeat: no-repeat;
      }
    }

    .form-checkbox {
      display: flex;
      align-items: center;
      padding: 2rem 1.5rem 2rem 1.5rem;
    }

    .custom-checkbox {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
      height: 4rem;
      width: 4rem;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      @media (max-width: 424px) {
        height: 3rem;
        width: 3rem;
      }

      &:checked {
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
          background-color: #78d960;
          border-radius: 50%;
          animation: animate 0.2s ease-in-out;

          @media (max-width: 424px) {
            width: 15px;
            height: 15px;
          }
        }

        @keyframes animate {
          0% {
            transform: translate(-50%, -50%) scale(0);
          }

          100% {
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }

    .form-check-input-new {
      position: absolute;
      margin-top: 0.3rem;
      margin-left: 0;
    }

    .form-check-label {
      font-size: 1.6rem;
      font-family: "Font Regular";
      color: #666666;
      text-align: left;
      margin-left: 6rem;
      display: flex;

      @media (max-width: 424px) {
        margin-left: 4rem;
        font-size: 1.2rem;
      }
    }

    .input-textarea {
      width: 100%;
      padding-inline: 1.5rem;

      .textarea {
        width: 100%;
        border-radius: 1rem;
        border: 1px solid #bfbfbf;
        font-size: var(--font-sz-d-text);
        font-family: "Font Regular";
        padding: 2rem;
        margin-top: 1.5rem;
        outline: 0;
        resize: none;
      }
    }

    .recaptcha {
      margin-top: 3rem;

      svg {
        @media (max-width: 991px) {
          width: 30rem;
        }
      }
    }
  }

  &-btn {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    @media (max-width: 991px) {
      margin-top: 0;
    }

    .btn-send {
      width: 36rem;
      height: 7rem;
      background-color: var(--primary-color);
      border-radius: 0.5rem;
      border: none;
      color: var(--white);
      font-size: 2rem;
      font-family: "Font Regular";
      margin-block: 2rem 2.5rem;
      transition: all 0.3s ease-in-out;

      @media (max-width: 991px) {
        width: 28rem;
      }

      &:hover {
        background-color: var(--white);
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
</style>
