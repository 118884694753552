import { axiosInstance } from '@/plugins/axios-instance';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import PasswordLogin from './PasswordLogin';

export default {
  name: "ModalLogin",
  data() {
    return {
      mobile: '',
      mobileCountryCode: '',
      mobileLogged: false,
      translations: {
        countrySelectorLabel: 'Região',
        phoneNumberLabel: 'Telemóvel',
      }
    }
  },
  components: {
    VuePhoneNumberInput,
    PasswordLogin
  },
  methods: {
    changePhone(payload) {
      if ('countryCallingCode' in payload) {
        this.mobileCountryCode = payload.countryCallingCode;
      }
    },
    handleSubmit($event) {
      axiosInstance({
        method: 'POST',
        url: 'customer/login-mobile',
        data: {
          mobile_country_code: this.mobileCountryCode,
          mobile: this.mobile
        }
      }).then(response => response.data.data)
        .then(data => {
          this.$bvModal.hide("modal-login");

          this.mobileLogged = true;

          setTimeout(() => {
            this.$bvModal.show("modal-login-password");
          }, 500);
        })
    },
  },
};
