import Vue from 'vue'
import Vuex from 'vuex'
import Site from './Site';
import Checkout from '@monorepo/zicket-vue-checkout/src/store/Checkout';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Site,    
    Checkout
  }
})
