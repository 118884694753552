<template>
  <div class="content-language">
    <div class="content-language-itens">
      <select v-model="localeChange">
        <option value="pt-pt">PT</option>
        <option value="en">EN</option>
        <option value="es">ES</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Site", ["locale"]),
    localeChange: {
      get() {
        // Recupera o idioma de zicket-locale ou usa o idioma padrão
        return localStorage.getItem('zicket-locale') || this.locale || 'pt-pt';
      },
      set(value) {
        this.setLocale(value);
        localStorage.setItem('zicket-locale', value);
        window.location.reload();
      },
    },
  },
  methods: {
    ...mapActions("Site", ["setLocale"]),
  },
};
</script>

<style lang="scss" scoped>
@import "./language.scss";
</style>
