<template>
  <div class="content-footer">
    <div class="content-footer-links container d-flex">
      <div class="content-footer-links-logo">
        <a tag="a" :href="urls.site">
          <img src="../../assets/img/footerL.png" alt="" />
        </a>
        <div class="content-footer-links-social">
          <a target="_blank" title="Facebook" href="https://www.facebook.com/zicket.pt/">
            <svg xmlns="http://www.w3.org/2000/svg" width="32.102" height="32.102" viewBox="0 0 32.102 32.102">
              <g id="Grupo_2343" data-name="Grupo 2343" transform="translate(-124 -5213.02)">
                <g id="Elipse_8" data-name="Elipse 8" transform="translate(124 5213.02)" fill="none" stroke="#c6004f"
                  stroke-width="1">
                  <ellipse cx="16.051" cy="16.051" rx="16.051" ry="16.051" stroke="none" />
                  <ellipse cx="16.051" cy="16.051" rx="15.551" ry="15.551" fill="none" />
                </g>
                <path id="Caminho_61" data-name="Caminho 61"
                  d="M29.323,7.23,29.68,4.9H27.448V3.394A1.163,1.163,0,0,1,28.76,2.138h1.015V.157A12.374,12.374,0,0,0,27.973,0a2.84,2.84,0,0,0-3.04,3.131V4.9H22.89V7.23h2.043v5.623h2.515V7.23Z"
                  transform="translate(113.719 5222.644)" />
              </g>
            </svg>
          </a>
          <a target="_blank" title="Instagram" href="https://www.instagram.com/zicket.pt/">
            <svg xmlns="http://www.w3.org/2000/svg" width="32.102" height="32.102" viewBox="0 0 32.102 32.102">
              <g id="Grupo_2344" data-name="Grupo 2344" transform="translate(-168.449 -5213.02)">
                <g id="Elipse_9" data-name="Elipse 9" transform="translate(168.449 5213.02)" fill="none"
                  stroke="#c6004f" stroke-width="1">
                  <ellipse cx="16.051" cy="16.051" rx="16.051" ry="16.051" stroke="none" />
                  <ellipse cx="16.051" cy="16.051" rx="15.551" ry="15.551" fill="none" />
                </g>
                <g id="XMLID_13_" transform="translate(178.51 5222.438)">
                  <path id="XMLID_17_"
                    d="M9.169,0H3.89A3.9,3.9,0,0,0,0,3.89V9.169a3.9,3.9,0,0,0,3.89,3.89H9.169a3.9,3.9,0,0,0,3.89-3.89V3.89A3.9,3.9,0,0,0,9.169,0Zm2.577,9.169a2.577,2.577,0,0,1-2.577,2.577H3.89A2.577,2.577,0,0,1,1.314,9.169V3.89A2.577,2.577,0,0,1,3.89,1.314H9.169A2.577,2.577,0,0,1,11.746,3.89V9.169Z" />
                  <path id="XMLID_81_"
                    d="M136.378,133a3.378,3.378,0,1,0,3.378,3.378A3.381,3.381,0,0,0,136.378,133Zm0,5.441a2.064,2.064,0,1,1,2.064-2.064A2.064,2.064,0,0,1,136.378,138.441Z"
                    transform="translate(-129.848 -129.848)" />
                  <ellipse id="XMLID_83_" cx="0.809" cy="0.809" rx="0.809" ry="0.809"
                    transform="translate(9.104 2.368)" />
                </g>
              </g>
            </svg>
          </a>
          <a target="_blank" title="Linkedin" href="https://www.linkedin.com/company/zonesoft/">
            <svg xmlns="http://www.w3.org/2000/svg" width="32.102" height="32.102" viewBox="0 0 32.102 32.102">
              <g id="Grupo_2345" data-name="Grupo 2345" transform="translate(-212.898 -5213.02)">
                <g id="Elipse_65" data-name="Elipse 65" transform="translate(212.898 5213.02)" fill="none"
                  stroke="#c6004f" stroke-width="1">
                  <ellipse cx="16.051" cy="16.051" rx="16.051" ry="16.051" stroke="none" />
                  <ellipse cx="16.051" cy="16.051" rx="15.551" ry="15.551" fill="none" />
                </g>
                <path id="Caminho_2827" data-name="Caminho 2827"
                  d="M2.888,12.913H.213V4.3H2.888ZM1.549,3.123A1.557,1.557,0,1,1,3.1,1.559,1.562,1.562,0,0,1,1.549,3.123ZM12.9,12.913H10.231V8.719c0-1-.02-2.281-1.391-2.281-1.391,0-1.6,1.086-1.6,2.209v4.266H4.564V4.3H7.13V5.473h.037A2.811,2.811,0,0,1,9.7,4.082c2.707,0,3.205,1.783,3.205,4.1v4.732Z"
                  transform="translate(223.115 5222.585)" />
              </g>
            </svg>
          </a>
        </div>
      </div>
      <div class="content-footer-links-company">
        <h3 class="title">
          <ZicketContent page_group="Titulos" origin="site" classElement="margin-0" identifier="Título Empresa"
            :lang="locale" :html="$t('footer.company')" />
        </h3>
        <router-link tag="a" to="/about">
          <ZicketContent page_group="Buttons" origin="site" classElement="margin-0" identifier="Botão Sobre Nós"
            :lang="locale" :html="$t('footer.aboutUs')" />
        </router-link>
      </div>
      <div class="content-footer-links-help">
        <h3 class="title">
          <ZicketContent page_group="Titulos" origin="site" classElement="margin-0" identifier="Título Contato"
            :lang="locale" :html="$t('footer.help')" />
        </h3>
        <router-link tag="a" to="/need-help">
          <ZicketContent page_group="Buttons" origin="site" classElement="margin-0" identifier="Botão Contato"
            :lang="locale" :html="$t('footer.contact')" />
        </router-link>
        <router-link tag="a" to="/faq">
          <ZicketContent page_group="Buttons" origin="site" classElement="margin-0" identifier="Botão FAQ"
            :lang="locale" :html="$t('footer.faq')" />
        </router-link>
      </div>
      <div class="content-footer-links-promisers">
        <h3 class="title">
          <ZicketContent page_group="Titulos" origin="site" classElement="margin-0" identifier="Título Promotores"
            :lang="locale" :html="$t('footer.promisers')" />
        </h3>
        <a tag="a" target="_blank" :href="urls.backend">
          <ZicketContent page_group="Buttons" origin="site" classElement="margin-0" identifier="Botão Área Reservada"
            :lang="locale" :html="$t('footer.reserved')" />
        </a>
      </div>
    </div>
    <div class="content-footer-credits">
      <div class="content-footer-credits-text d-flex container">
        <p>© zicket, {{ new Date() | moment("YYYY") }}</p>
        <div class="content-footer-credits-text-terms">
          <router-link tag="a" to="/privacy">
            <ZicketContent page_group="Buttons" origin="site" identifier="Botão Política de Privacidade" :lang="locale"
              :html="$t('footer.politics')" />
          </router-link>
          <router-link tag="a" to="/terms">
            <ZicketContent page_group="Buttons" origin="site" identifier="Botão Termos e Condições" :lang="locale"
              :html="$t('footer.terms')" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  data() {
    return {
      footer: {
        faq: "Faq's",
        promisers: "Promisers",
        reserved: "Área Reservada",
      },
    };
  },
  computed: {
    ...mapGetters("Site", ["urls", "locale"]),
  },
};
</script>
<style lang="scss" scoped>
.content-footer {
  width: 100%;
  height: 100%;
  height: 20rem;
  background-color: var(--white);
  box-shadow: 0px -5px 8px -9px rgba(0, 0, 0, 0.75);

  @media (max-width: 991px) {
    height: 100%;
  }

  &-links {
    height: 100%;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-direction: column;
      margin-bottom: 6rem;
      gap: 6rem;
    }

    .title {
      font-size: 2rem;
      color: #c6004f;
      font-family: "Font Regular";

      p {
        margin: 0;
      }
    }

    a {
      text-decoration: none;
      color: #252525;
      font-size: var(--font-sz-d-text);
      font-family: "Font Regular";
    }

    &-logo {
      display: flex;
      flex-direction: column;
      gap: 2.7rem;
      width: 18rem;
      height: 100%;
      padding-top: 6rem;

      @media (max-width: 991px) {
        width: 100%;
      }

      img {
        max-width: 18rem;
        max-height: 5rem;
      }
    }

    &-company {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      padding-top: 6rem;

      @media (max-width: 991px) {
        align-items: center;
        gap: 1.5rem;
        padding-top: 0rem;
      }
    }

    &-help {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      padding-top: 6rem;

      @media (max-width: 991px) {
        align-items: center;
        gap: 1.5rem;
        padding-top: 0rem;
      }
    }

    &-promisers {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      padding-top: 6rem;

      @media (max-width: 991px) {
        align-items: center;
        gap: 1.5rem;
        padding-top: 0rem;
      }
    }

    &-social {
      display: flex;
      gap: 2.5rem;

      @media (max-width: 991px) {
        justify-content: center;
      }
    }
  }

  &-credits {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 9rem;
    background-color: var(--primary-color);

    @media (max-width: 991px) {
      height: 100%;
    }

    &-text {
      height: 100%;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 991px) {
        justify-content: center;
        flex-direction: column;
        padding-block: 2rem;
        gap: 1rem;
      }

      p {
        margin: 0;
        color: var(--white);
        font-size: var(--font-sz-d-text);
        font-family: "Font Light";

        @media (max-width: 991px) {
          font-size: var(--font-sz-m-subtitle);
        }
      }

      &-terms {
        display: flex;
        gap: 6rem;

        a {
          color: var(--white);
          font-size: var(--font-sz-d-text);
          font-family: "Font Light";
        }

        @media (max-width: 991px) {
          flex-direction: column;
          align-items: center;
          gap: 1rem;
        }
      }
    }
  }
}
</style>
