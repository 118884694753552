export default {
    isLoading: state => state.loading,
    urls: state => state.urls,
    eventData: state => state.eventData,
    customer: state => state.customer,
    cards: state => state.cards,
    transactions: state => state.transactions,
    locale: state => (state.locale || localStorage.getItem('zicket-locale')),
    visitorId: state => (state.visitorId || localStorage.getItem('zicket-visitorId')),
    menuOpen(state) {
        return state.menuOpen;
    },
    activeMenuFooter(state) {
        return state.activeMenuFooter;
    },
    activeFormFloating(state) {
        return state.activeFormFloating;
    },
    isDesktop(state) {
        return state.isDesktop;
    },
};
